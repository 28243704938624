<template>
    <div>
        <h1>Scrap Tracking</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import Options from './scrap_tracking_opt.vue';
    import Results from './utils/Results';

    export default {
        name: "scrap_tracking",
        components: {
            Options,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    checkDateFrom: {val: new Date(new Date().getFullYear(), 0, 1).toLocaleDateString(), qsField: '[dates][check_date_from]'},
                    checkDateTo: {val: new Date().toLocaleDateString(), qsField: '[dates][check_date_to]'},
                    cdiLocationID: {val: 0, qsField: '[numbers][cl.cdi_location_id]'}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Scrap Tracking']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>